import { Avatar, Box, Container, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import Bg from '../../../../assets/landing/mourafaka-bg.jpg';
import { Img } from '../../../../components';
import { PROGRAMMES } from '../../../../constants/landing';

const Mourafaka = () => {
    return (
        <Box
            sx={(theme) => ({
                position: 'relative',
                backgroundImage: `url(${Bg})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom',
                backgroundSize: 'cover',
            })}
        >
            <Box
                sx={(theme) => ({
                    py: 10,
                    background: theme.gradient.programme.mourafaka,
                })}
            >
                <Container>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item md={6} xs={12}>
                            <Stack spacing={4}>
                                {PROGRAMMES.mourafaka.bullets.map((item) => (
                                    <Box display="flex" alignItems="center" gap={3}>
                                        <Stack spacing={1}>
                                            <Typography
                                                sx={(theme) => ({
                                                    fontSize: theme.fontSize.lg,
                                                    fontWeight: theme.fontWeight.semibold,
                                                    color: theme.palette.common.white,
                                                    textAlign: 'right',
                                                })}
                                            >
                                                {item.title}
                                            </Typography>
                                            <Typography
                                                sx={(theme) => ({
                                                    fontSize: theme.fontSize.sm,
                                                    fontWeight: theme.fontWeight.light,
                                                    color: theme.palette.common.white,
                                                    textAlign: 'right',
                                                })}
                                            >
                                                {item.desc}
                                            </Typography>
                                        </Stack>
                                        <Avatar
                                            sx={(theme) => ({
                                                backgroundColor: 'transparent',
                                                width: 60,
                                                height: 60,
                                                color: theme.palette.mourafaka.second,
                                                fontSize: theme.fontSize['4xl'],
                                            })}
                                        >
                                            {item.icon}
                                        </Avatar>
                                    </Box>
                                ))}
                            </Stack>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <Stack spacing={3}>
                                <Img
                                    src={PROGRAMMES.mourafaka.logoWhite}
                                    sx={(theme) => ({
                                        width: 200,
                                        transition: 'all .3s ease',
                                        // boxShadow: theme.shadows.png,
                                    })}
                                />
                                <Typography
                                    sx={(theme) => ({
                                        fontSize: theme.fontSize.md,
                                        fontWeight: theme.fontWeight.medium,
                                        transition: 'all .3s ease',
                                        color: theme.palette.common.white,
                                        textAlign: 'justify',
                                        lineHeight: 2,
                                    })}
                                >
                                    {PROGRAMMES.mourafaka.desc}
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
};

export default Mourafaka;
