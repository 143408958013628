export const PAGES = {
    landing: {
        title: 'CoopLab | Bienvenu',
        url: '/',
    },
    logIn: {
        title: 'CoopLab | Se connecter',
        url: '/log-in',
    },

    // ------- user
    user: {
        url: '/dashboard',
    },
    userOverview: {
        title: "CoopLab | Tableau de bord | Vue d'ensemble",
        url: 'overview',
    },
    userApplications: {
        title: 'CoopLab | Tableau de bord | Candidatures',
        url: 'applications',
    },
    userServices: {
        title: 'CoopLab | Tableau de bord | Services',
        url: 'services',
    },
    userOneService: {
        title: 'CoopLab | Tableau de bord | Service',
        url: 'service',
    },
    userProfile: {
        title: 'CoopLab | Tableau de bord | Profile',
        url: 'profile',
    },

    // ------- admin
    admin: {
        url: '/admin',
    },
    adminApplications: {
        title: 'CoopLab | Admin | Candidatures',
        url: 'applications',
    },

    // ------- 404
    notFound: {
        title: 'CoopLab | Oops!',
        url: '*',
    },
};
