import { Card } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme, color }) => ({
    padding: theme.spacing(2),
    boxShadow: 'none',
    width: '100%',
    borderRadius: theme.borderRadius.md,
    border: '2px solid',
    background: 'transparent',
    borderColor: color ? alpha(theme.palette[color].main, 0.25) : theme.palette.muted.main,
}));

export default StyledCard;
