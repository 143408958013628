import { AppBar, Box, Container, Divider, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { Logo } from '..';
import Bg from '../../assets/background/blob-scatter-2.png';

const Footer = ({ dashboard }) => {
    return (
        <Box
            sx={(theme) => ({
                position: 'relative',
                backgroundImage: `url(${Bg})`,
                backgroundRepeat: 'repeat',
                backgroundPosition: 'bottom',
                backgroundSize: 'contain',
                mt: dashboard ? 5 : 0,
            })}
        >
            <Box
                sx={(theme) => ({
                    background: dashboard ? 'transparent' : theme.gradient.primaryAlpha,
                    py: 10,
                })}
            >
                <Container>
                    <AppBar
                        position="static"
                        sx={(theme) => ({
                            boxShadow: 'none',
                            backgroundColor: 'transparent',
                        })}
                    >
                        <Container>
                            <Toolbar spacing={5} sx={{ py: 3 }}>
                                <Box width="fit-content">
                                    <Logo lg square white={!dashboard} />
                                </Box>
                                {/* <Box
                                width={{ sm: 'fit-content', xs: '100%' }}
                                sx={{ mx: 'auto', flexGrow: 1, display: 'flex', gap: 2, flexDirection: { sm: 'row', xs: 'column' } }}
                            >
                                {CONTACT.map((page, index) => (
                                    <Box display="flex" alignItems="center">
                                        <Avatar
                                            sx={(theme) => ({
                                                backgroundColor: 'transparent',
                                                color: theme.palette.common.white,
                                                width: 30,
                                                height: 30,
                                            })}
                                        >
                                            {page.icon}
                                        </Avatar>
                                        <Typography
                                            sx={(theme) => ({
                                                fontSize: theme.fontSize.sm,
                                                color: theme.palette.common.white,
                                            })}
                                        >
                                            {page.text}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box> */}
                                <Box display="flex" gap={1} alignItems="center" ml="auto">
                                    {/* {SOCIAL.map((item, index) => {
                                    return (
                                        <Link key={index} to={item.url} target="_blank">
                                            <IconButton
                                                sx={(theme) => ({
                                                    color: theme.palette.common.white,
                                                })}
                                            >
                                                {item.icon}
                                            </IconButton>
                                        </Link>
                                    );
                                })} */}
                                </Box>
                            </Toolbar>
                            <Divider
                                sx={(theme) => ({
                                    width: '100%',
                                    borderColor: dashboard ? theme.palette.grey[300] : theme.palette.secondary.main,
                                })}
                            />
                            <Toolbar sx={{ pt: 3, flexDirection: 'column', alignItems: 'flex-start' }}>
                                <Typography
                                    sx={(theme) => ({
                                        color: dashboard ? theme.palette.grey[600] : theme.palette.common.white,
                                        fontWeight: theme.fontWeight.light,
                                        fontSize: theme.fontSize.xs,
                                    })}
                                >
                                    Use of this site constitutes acceptance of our User Agreement and Privacy Policy.
                                </Typography>
                            </Toolbar>
                        </Container>
                    </AppBar>
                </Container>
            </Box>
        </Box>
    );
};

export default Footer;
