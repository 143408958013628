import { Box, Container, Grid, Stack, Typography, alpha } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import Bg from '../../../assets/background/blob-scatter-1.png';
import { Card, Img, Title } from '../../../components';
import { ENTITES } from '../../../constants/landing';
import { NAVBAR_PAGES } from '../../../constants/navbar';

const Entites = () => {
    const [hover, setHover] = useState(-1);
    const handleHoverOn = (index) => setHover(index);
    const handleHoverOff = () => setHover(-1);

    return (
        <Box
            sx={(theme) => ({
                backgroundImage: `url(${Bg})`,
                backgroundRepeat: 'repeat',
                backgroundPosition: 'center',
                backgroundSize: 'contain',
            })}
            id={NAVBAR_PAGES[1].id}
        >
            <Container sx={{ alignSelf: 'center' }}>
                <Title>Entités CoopLab</Title>
                <Grid container spacing={5}>
                    {ENTITES.map((item, index) => (
                        <Grid item md={3} sm={6} xs={12}>
                            <motion.div
                                whileHover={{ scale: 1.05 }}
                                transition={{ duration: 0.3 }}
                                onHoverStart={(e) => {
                                    handleHoverOn(index);
                                }}
                                onHoverEnd={(e) => {
                                    handleHoverOff();
                                }}
                                style={{ height: '100%' }}
                            >
                                <Card
                                    sx={(theme) => ({
                                        height: '100%',
                                        border: '4px solid',
                                        // borderRight: '2px solid',
                                        borderColor:
                                            hover === index
                                                ? theme.palette.primary.main
                                                : alpha(theme.palette.secondary.main, (ENTITES.length - index + 1) / ENTITES.length),
                                        cursor: 'pointer',
                                        transition: 'all .3s ease',
                                        py: 5,
                                    })}
                                >
                                    <Stack spacing={2}>
                                        <Img
                                            src={item.image}
                                            sx={(theme) => ({
                                                width: 150,
                                                alignSelf: 'flex-start',
                                                transition: 'all .3s ease',
                                            })}
                                        />
                                        <Stack spacing={0.25}>
                                            <Typography
                                                sx={(theme) => ({
                                                    fontWeight: theme.fontWeight.bold,
                                                    fontSize: theme.fontSize.lg,
                                                    transition: 'all .3s ease',
                                                    color: theme.palette.common.black,
                                                    alignSelf: 'flex-start',
                                                })}
                                            >
                                                {item.title}
                                            </Typography>
                                            <Typography
                                                sx={(theme) => ({
                                                    fontWeight: theme.fontWeight.thin,
                                                    fontSize: theme.fontSize['2xs'],
                                                    transition: 'all .3s ease',
                                                    color: theme.palette.grey[600],
                                                    alignSelf: 'flex-start',
                                                })}
                                            >
                                                {item.sub}
                                            </Typography>
                                        </Stack>
                                        <Typography
                                            sx={(theme) => ({
                                                fontSize: theme.fontSize.sm,
                                                fontWeight: theme.fontWeight.light,
                                                transition: 'all .3s ease',
                                                color: theme.palette.common.black,
                                                textAlign: 'justify',
                                            })}
                                        >
                                            {item.desc}
                                        </Typography>
                                    </Stack>
                                </Card>
                            </motion.div>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export default Entites;
