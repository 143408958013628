import React, { useEffect } from 'react';
import { useOutletContext, useLocation } from 'react-router-dom';
import { LANG } from '../../constants/generales';
import Frensh from './Frensh';

const Landing = () => {
    // ========== states
    const { states } = useOutletContext();
    const { lang } = states;

    const { hash } = useLocation();

    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
            window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    window.scrollTo({
                        top: element.offsetTop - 150,
                        left: 0,
                        behavior: 'smooth',
                    });
                }
            }, 0);
        }
    }, [hash]);

    return <>{lang === LANG.FR && <Frensh />}</>;
};

export default Landing;
