import { Box, Container, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import ImageLong from '../../../assets/landing/about-us-long.jpg';
import Image from '../../../assets/landing/about-us.jpg';
import { Img, Title } from '../../../components';
import Bg from '../../../assets/background/blob-4.png';
import { NAVBAR_PAGES } from '../../../constants/navbar';

const AboutUs = () => {
    const md = useMediaQuery((theme) => theme.breakpoints.up('md'));

    return (
        <Box
            sx={(theme) => ({
                backgroundImage: `url(${Bg})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'contain',
            })}
            id={NAVBAR_PAGES[0].id}
        >
            <Container sx={{ alignSelf: 'center' }}>
                {!md && <Title>À propos de nous</Title>}
                <Grid container spacing={10} justifyContent="space-between">
                    <Grid item md={4} xs={9} sx={{ position: 'relative' }}>
                        <Img
                            src={ImageLong}
                            sx={(theme) => ({
                                boxShadow: theme.shadows.default,
                                width: '100%',
                                borderRadius: theme.borderRadius.md,
                                border: '5px solid',
                                borderColor: theme.palette.common.white,
                            })}
                        />
                        <Img
                            src={Image}
                            sx={(theme) => ({
                                boxShadow: theme.shadows.default,
                                width: 200,
                                borderRadius: theme.borderRadius.md,
                                position: 'absolute',
                                top: '100%',
                                transform: 'translate(-50%, -80%)',
                                left: '100%',
                                border: '5px solid',
                                borderColor: theme.palette.common.white,
                            })}
                        />
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <Stack spacing={3}>
                            {md && <Title>À propos de nous</Title>}
                            <Typography
                                sx={(theme) => ({
                                    fontWeight: theme.fontWeight.light,
                                    fontSize: theme.fontSize.md,
                                    color: theme.palette.common.black,
                                    lineHeight: 2,
                                    textAlign: 'justify',
                                    // textAlignLast: 'center',
                                })}
                            >
                                {/* Depuis sa création en 2020, le Cooplab s'est engagé à devenir une pépinière de compétences au service de
                                l'écosystème coopératif. Il offre des services d'accompagnement professionnel aux coopératrices et
                                coopérateurs. Tout en promouvant et en partageant les bonnes pratiques. Bâtir un écosystème coopératif
                                inclusif, solidaire, autonomisant et innovant est au cœur des engagements du Cooplab pour élargir son
                                impact. */}
                                C'est un « Brave Space» au profit d'équipes multidisciplinaires et des parties prenantes pour co-construire
                                des solutions innovantes en matière de développement humain.
                            </Typography>

                            <Typography
                                sx={(theme) => ({
                                    fontWeight: theme.fontWeight.light,
                                    fontSize: theme.fontSize.md,
                                    color: theme.palette.common.black,
                                    lineHeight: 2,
                                    textAlign: 'justify',
                                    // textAlignLast: 'center',
                                })}
                            >
                                {/* Consciente du rôle crucial des coopératives dans la promotion de la durabilité économique et du bien-être
                                social, l'équipe du Cooplab a pour ambition d'habiliter le secteur coopératif avec un modèle social
                                innovant. À cette fin, le Cooplab repose sur quatre entités qui forment ses piliers : Cooplab Growth,
                                Cooplab Thinking, Cooplab Link's et Cooplab. Chacune de ces entités propose une gamme de services. */}
                                Le CoopLab tire profit de l'expérience de la Fondation OCP et de l'expertise scientifique et technique de
                                l'UM6P en matière d'accompagnement des coopératives, dans l'intérêt de favoriser l'acquisition de
                                connaissances et apporter un soutien aux initiatives visant à renforcer le développement durable des
                                communautés et des territoires.
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default AboutUs;
