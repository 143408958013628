import MuiList from '@mui/material/List';
import { alpha, styled } from '@mui/material';

const StyledList = styled(MuiList)(({ theme }) => ({
    width: '100%',
    gap: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    '& .MuiListItemButton-root': {
        // borderTopLeftRadius: theme.borderRadius.xl,
        // borderBottomLeftRadius: theme.borderRadius.xl,
        borderRadius: theme.borderRadius.md,
        position: 'relative',
        // backgroundColor: theme.palette.common.greyLight,
        backgroundColor: 'transparent',
        // color: theme.palette.common.grey,
        color: theme.palette.common.white,
        transition: 'all 0.3s ease',
        '&:hover': {
            // backgroundColor: theme.palette.primary.main,
            backgroundColor: alpha(theme.palette.common.white, 0.25),
            color: theme.palette.common.white,
            // '& .MuiListItemIcon-root': {
            //     color: theme.palette.common.white,
            // },
        },
    },
    '& .Mui-selected': {
        // backgroundColor: theme.palette.primary.main + ' !important',
        backgroundColor: alpha(theme.palette.common.white, 0.25) + ' !important',
        color: theme.palette.common.white,
        '& .MuiListItemIcon-root': {
            color: theme.palette.common.white,
        },
    },

    '.MuiListItemText-primary': {
        fontSize: theme.fontSize.sm,
        fontWeight: theme.fontWeight.semibold,
    },
    '& .MuiListItemIcon-root': {
        minWidth: 0,
        marginRight: 12,
        // fontSize: theme.fontSize['xl'],
        fontSize: theme.fontSize.md,
        // color: theme.palette.common.grey,
        color: theme.palette.common.white,
    },
}));

export default StyledList;
