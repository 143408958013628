export const NAVBAR_PAGES = [
    {
        title: 'À propos',
        id: 'a-propos',
    },
    {
        title: 'Entités',
        id: 'entite',
    },
    {
        title: 'Réalisations',
        id: 'realisations',
    },
    {
        title: 'Partenaires',
        id: 'partenaires',
    },
];
