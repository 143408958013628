import { styled, Badge } from '@mui/material';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: '50%',
        border: `3px solid ${theme.palette.muted.main}`,
        padding: '0 4px',
    },
}));

export default StyledBadge;
