// ======= STATES
export const RESET_GENERAL_STATES = 'RESET_GENERAL_STATES';

// ======= PROGRESS
export const ACTION_IN_PROGRESS = 'ACTION_IN_PROGRESS';
export const ACTION_FINISHED = 'ACTION_FINISHED';

// ======= ALERT
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const SET_ALERT = 'SET_ALERT';

// ======= AUTH
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
