import { LANG } from '../constants/generales';
import {
    ACTION_FINISHED,
    ACTION_IN_PROGRESS,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    REMOVE_ALERT,
    SET_ALERT,
    RESET_GENERAL_STATES,
} from './actionsType';

export const initialData = {
    // ============ general states
    loader: false,
    alert: null,
    lang: LANG.FR,
};

export const reducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        // ====== STATES
        case RESET_GENERAL_STATES:
            return { ...initialData, ...state, loader: initialData.loader, alert: initialData.alert };

        // ====== PROGRESS
        case ACTION_IN_PROGRESS:
            return { ...state, loader: true };
        case ACTION_FINISHED:
            return { ...state, loader: false };

        // ======= ALERT
        case SET_ALERT:
            return { ...state, alert: payload };
        case REMOVE_ALERT:
            return { ...state, alert: null };

        // ======= AUTH
        case LOGIN_SUCCESS:
            return { ...state, auth: payload };
        case LOGOUT_SUCCESS:
            return { ...initialData };

        default:
            break;
    }
};
