import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import Line from '../Line';

const Title = ({ children, white }) => {
    return (
        <Stack spacing={0.5} alignItems="flex-start" mb={7.5}>
            <Typography
                sx={(theme) => ({
                    fontSize: theme.fontSize['3xl'],
                    fontWeight: theme.fontWeight.bold,
                    color: white ? theme.palette.common.white : theme.palette.primary.main,
                    textAlign: 'center',
                })}
            >
                {children}
            </Typography>
            <Box alignItems="center" gap={0.5} width="100%" display="flex">
                <Line white={white} />
                <Line white={white} smallLine />
            </Box>
        </Stack>
    );
};

export default Title;
