import { Box, Container, Stack } from '@mui/material';
import React from 'react';
import Slider from 'react-slick';
import Slide1 from '../../../assets/landing/partenaire/minister.png';
import Slide2 from '../../../assets/landing/partenaire/ocp.png';
import Slide3 from '../../../assets/landing/partenaire/odc.png';
import { Img, Title } from '../../../components';
import { NAVBAR_PAGES } from '../../../constants/navbar';

const images = [Slide1, Slide2, Slide3, Slide1, Slide2, Slide3, Slide1, Slide2, Slide3, Slide1, Slide2, Slide3];
const Partenaires = () => {
    return (
        <Container sx={{ alignSelf: 'center' }}>
            <Stack id={NAVBAR_PAGES[3].id}>
                <Box alignSelf="center">
                    <Title center>Nos Partenaires</Title>
                </Box>
                <Slider
                    {...{
                        dots: false,
                        infinite: true,
                        slidesToShow: 6,
                        slidesToScroll: 1,
                        autoplay: true,
                        autoplaySpeed: 1500,
                        arrows: false,
                        responsive: [
                            {
                                breakpoint: 1200,
                                settings: {
                                    slidesToShow: 3,
                                    slidesToScroll: 3,
                                },
                            },
                            {
                                breakpoint: 900,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 2,
                                },
                            },
                            {
                                breakpoint: 600,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                },
                            },
                        ],
                    }}
                >
                    {images.map((image) => (
                        <Img sx={{ width: 100, px: 3.5 }} src={image} />
                    ))}
                </Slider>
            </Stack>
        </Container>
    );
};

export default Partenaires;
