import { Pagination as MuiPagination, PaginationItem } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import {
    DataGrid as MuiDataGrid,
    gridFilteredTopLevelRowCountSelector,
    gridPageSelector,
    gridPageSizeSelector,
    useGridApiContext,
    useGridRootProps,
    useGridSelector,
} from '@mui/x-data-grid';
import React from 'react';

export const Pagination = () => {
    const apiRef = useGridApiContext();
    const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
    const page = useGridSelector(apiRef, gridPageSelector);
    const visibleTopLevelRowCount = useGridSelector(apiRef, gridFilteredTopLevelRowCountSelector);
    const rootProps = useGridRootProps();
    const pageCount = getPageCount(rootProps.rowCount ?? visibleTopLevelRowCount, pageSize);

    return (
        <MuiPagination
            color="primary"
            variant="outlined"
            shape="rounded"
            page={page + 1}
            count={pageCount}
            renderItem={(props2) => (
                <PaginationItem
                    sx={(theme) => ({
                        '&.MuiPaginationItem-root': {
                            borderRadius: theme.borderRadius.md,
                            border: 0,
                            '&.Mui-disabled': {
                                backgroundColor: theme.palette.grey[300],
                            },
                        },
                    })}
                    {...props2}
                    disableRipple
                />
            )}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
            pageSizeOptions={[5, 10, 25]}
        />
    );
};

const customCheckbox = (theme) => {
    return {
        '& .MuiCheckbox-root svg': {
            width: 16,
            height: 16,
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.muted.main}`,
            borderRadius: theme.borderRadius.md,
        },
        '& .MuiCheckbox-root svg path': {
            display: 'none',
        },
        '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
            backgroundColor: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
        },
        '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
            position: 'absolute',
            display: 'table',
            border: '2px solid #fff',
            borderTop: 0,
            borderLeft: 0,
            transform: 'rotate(45deg) translate(-50%,-50%)',
            opacity: 1,
            transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
            content: '""',
            top: '50%',
            left: '39%',
            width: 5.71428571,
            height: 9.14285714,
        },
        '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
            width: 8,
            height: 8,
            backgroundColor: theme.palette.secondary.main,
            transform: 'none',
            top: '39%',
            border: 0,
        },
    };
};

export const DataGrid = styled((props) => (
    <MuiDataGrid
        getRowHeight={() => 'auto'}
        disableRowSelectionOnClick
        initialState={{
            columns: {
                columnVisibilityModel: { id: false },
            },
        }}
        slots={{
            pagination: Pagination,
        }}
        {...props}
    />
))(({ theme }) => ({
    '&>.MuiDataGrid-main': {
        '&>.MuiDataGrid-columnHeaders': {
            borderBottom: 'none',
        },

        '& div div div div >.MuiDataGrid-cell': {
            borderBottom: 'none',
        },
    },
    '& ::-webkit-scrollbar': {
        height: 8,
    },
    '& ::-webkit-scrollbar-track': {
        backgroundColor: theme.palette.muted.main,
    },
    '& ::-webkit-scrollbar-thumb': {
        borderRadius: theme.borderRadius.md,
        backgroundColor: alpha(theme.palette.primary.main, 0.5),
    },

    color: theme.palette.common.black,
    WebkitFontSmoothing: 'auto',
    width: '100%',
    border: 0,
    overflow: 'hidden',

    '& .MuiDataGrid-iconSeparator': {
        display: 'none',
    },
    '& .MuiDataGrid-withBorderColor': {
        borderRadius: 0,
    },
    '& .MuiDataGrid-columnHeader': {
        color: theme.palette.common.white,
        fontSize: theme.fontSize.md,
        backgroundColor: theme.palette.primary.main,
        border: 0,
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
        // borderRight: `1px solid ${theme.palette.muted.main}`,
        border: 0,
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
        // borderBottom: `1px solid ${theme.palette.muted.main}`,
        fontSize: theme.fontSize.sm,
    },
    '& .MuiDataGrid-cell': {
        color: theme.palette.grey[700],
        fontWeight: theme.fontWeight.semibold,
        // justifyContent: 'center',
        padding: theme.spacing(1),
    },
    '& .MuiPaginationItem-root': {
        borderRadius: 0,
    },
    '& .MuiDataGrid-footerContainer': {
        border: 0,
        // borderTop: `1px solid ${theme.palette.muted.main}`,
        padding: theme.spacing(3),
        borderLeft: 0,
        borderRight: 0,
        borderBottom: 0,
    },
    ...customCheckbox(theme),
}));

const getPageCount = (rowCount, pageSize) => {
    if (pageSize > 0 && rowCount > 0) {
        return Math.ceil(rowCount / pageSize);
    }

    return 0;
};
