import { Divider } from '@mui/material';
import React from 'react';

const Line = ({ white, smallLine }) => {
    return (
        <Divider
            sx={(theme) => ({
                width: '100%',
                border: '2px solid',
                borderColor: white ? theme.palette.common.white : theme.palette.secondary.main,
                maxWidth: smallLine ? '1rem' : '3rem',
                borderRadius: theme.borderRadius.full,
            })}
        />
    );
};

export default Line;
