import { Box, Stack } from '@mui/material';
import React from 'react';
import Sookoa from './Sookoa';
import { Title } from '../../../../components';
import Mourafaka from './Mourafaka';
import Rawabit from './Rawabit';
import { NAVBAR_PAGES } from '../../../../constants/navbar';

const Programmes = () => {
    return (
        <Box id={NAVBAR_PAGES[2].id}>
            <Stack alignItems="center">
                <Title>Nos réalisations</Title>
            </Stack>
            <Stack spacing={1}>
                <Sookoa />
                <Mourafaka />
                <Rawabit />
            </Stack>
        </Box>
    );
};

export default Programmes;
