import {
    ACTION_FINISHED,
    ACTION_IN_PROGRESS,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    REMOVE_ALERT,
    RESET_GENERAL_STATES,
    SET_ALERT
} from './actionsType';

// ========== STATES
export const resetGeneralStates = () => ({ type: RESET_GENERAL_STATES });

// ========== LOADER
export const actionInProgress = () => ({ type: ACTION_IN_PROGRESS });
export const actionFinished = () => ({ type: ACTION_FINISHED });

// ========== ALERT
export const setAlert = (payload) => ({ type: SET_ALERT, payload });
export const removeAlert = () => ({ type: REMOVE_ALERT });

// ========== AUTH
export const loginSuccess = (payload) => ({ type: LOGIN_SUCCESS, payload });
export const logOutSuccess = () => ({ type: LOGOUT_SUCCESS });
