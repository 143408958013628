import { blueGrey } from '@mui/material/colors';
import { alpha, createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#396dad',
        },
        secondary: {
            main: '#90ce38',
        },
        muted: {
            main: blueGrey[50],
            // E6EBEE
        },
        yellow: {
            main: '#F7C35F',
        },

        // ----------- program
        sookoa: {
            main: '#1c1d52',
            second: '#49ce61',
        },
        mourafaka: {
            main: '#aa0b1d',
            second: '#f1b842',
        },
        rawabit: {
            main: '#9fd447',
            second: '#4782bb',
        },
        // ----------- fixs
        white: {
            main: '#fff',
        },
    },
    typography: {
        fontFamily: "'Poppins', sans-serif",
    },
    fontSize: {
        micro: '0.35rem',
        '2xs': '0.65rem',
        xs: '0.75rem',
        sm: '0.875rem',
        md: '1rem',
        lg: '1.125rem',
        xl: '1.25rem',
        '2xl': '1.5rem',
        '3xl': '1.875rem',
        '4xl': '2.25rem',
        '5xl': '3rem',
        '6xl': '3.75rem',
        '7xl': '4.5rem',
        '8xl': '6rem',
        '9xl': '8rem',
    },
    borderRadius: {
        none: '0',
        sm: '0.125rem',
        base: '0.25rem',
        md: '0.375rem',
        lg: '0.5rem',
        xl: '0.75rem',
        '2xl': '1rem',
        '3xl': '1.5rem',
        full: '9999px',
    },
    fontWeight: {
        thin: 200,
        extralight: 200,
        light: 300,
        normal: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
        extrabold: 800,
        black: 900,
    },
    blur: {
        primary: 'blur(10px)',
    },
});
// primary: {
//     background: 'linear-gradient(90deg, rgba(229,229,208,1) 0%, rgba(0,47,108,1) 100%);',
//     WebkitBackgroundClip: 'text',
//     WebkitTextFillColor: 'transparent',
// },
// ============== gradient

theme.gradient = {
    primary: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    secondary: `linear-gradient(210deg, ${theme.palette.primary.main} 40%, ${theme.palette.secondary.main} 100%)`,

    // -----

    primaryOne: `linear-gradient(90deg, ${alpha(theme.palette.primary.main, 0.9)} 0%, ${alpha(theme.palette.primary.main, 0.25)} 100%)`,
    secondaryOne: `linear-gradient(90deg, ${alpha(theme.palette.secondary.main, 0.9)} 0%, ${alpha(
        theme.palette.secondary.main,
        0.25
    )} 100%)`,

    // -----

    primaryAlpha: `linear-gradient(90deg, ${alpha(theme.palette.primary.main, 0.7)} 0%, ${alpha(theme.palette.secondary.main, 0.7)} 100%)`,
    secondaryAlpha: `linear-gradient(45deg, ${alpha(theme.palette.primary.main, 0.5)} 0%, ${alpha(
        theme.palette.secondary.main,
        0.5
    )} 100%)`,

    // -----

    whiteAlpha: `linear-gradient(45deg, ${alpha(theme.palette.secondary.main, 0.9)} 0%, ${alpha(theme.palette.common.white, 0.9)} 40%)`,

    // -----

    common: {
        error: `linear-gradient(50deg, ${alpha(theme.palette.error.main, 0.75)} 0%, ${alpha(theme.palette.error.main, 0.5)} 50%)`,
        yellow: `linear-gradient(50deg, ${alpha(theme.palette.yellow.main, 0.75)} 0%, ${alpha(theme.palette.yellow.main, 0.5)} 50%)`,
        success: `linear-gradient(50deg, ${alpha(theme.palette.success.main, 0.75)} 0%, ${alpha(theme.palette.success.main, 0.5)} 50%)`,
    },

    // ----
    programme: {
        sookoa: `linear-gradient(220deg, ${alpha(theme.palette.sookoa.main, 1)} 0%, ${alpha(theme.palette.sookoa.second, 0.5)} 100%)`,
        rawabit: `linear-gradient(220deg, ${alpha(theme.palette.rawabit.main, 1)} 0%, ${alpha(theme.palette.rawabit.second, 0.5)} 100%)`,
        mourafaka: `linear-gradient(220deg, ${alpha(theme.palette.mourafaka.main, 1)} 0%, ${alpha(
            theme.palette.mourafaka.second,
            0.5
        )} 100%)`,
    },
};

theme.palette.common.black = theme.palette.grey['900'];
theme.palette.common.grey = blueGrey[500];
theme.palette.common.greyLight = blueGrey[50];
theme.palette.greyDark = {
    main: blueGrey[500],
};

// =============== SHADOW
theme.shadows = {
    ...theme.shadows,
    default: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;',
    png: {
        WebkitFilter: 'drop-shadow(0px 6px 6px rgba(0,0,0,0.2))',
        filter: 'drop-shadow(0px 6px 6px rgba(0,0,0,0.2))',
    },
};

// =============== css override
theme.components = {
    ...theme.components,
    MuiCssBaseline: {
        styleOverrides: `          
  		`,
    },
};

export default theme;
