import { AppBar, Container, Toolbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { LANG } from '../../constants/generales';
import { useScrollPosition } from '../../hooks';
import Frensh from './Frensh';

const Navbar = () => {
    // ========== states
    const { states } = useOutletContext();
    const { lang } = states;

    // const scrollDirection = useScrollDirection();
    const scrollPosition = useScrollPosition();
    const [sticky, setSticky] = useState(false);

    useEffect(() => {
        setSticky(scrollPosition >= 50);
    }, [scrollPosition]);

    return (
        <AppBar
            position="fixed"
            sx={(theme) => ({
                backgroundColor: sticky ? theme.palette.common.white : 'transparent',
                boxShadow: sticky ? theme.shadows.default : 'none',
                transition: 'all .3s ease',
            })}
        >
            <Container>
                <Toolbar sx={{ py: 3, justifyContent: 'space-between' }}>
                    {lang === LANG.FR && <Frensh sticky={sticky} />}
                    {/* {lang === LANG.AR && <Arabic sticky={sticky} />}
                    {lang === LANG.EN && <English sticky={sticky} />} */}
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Navbar;
