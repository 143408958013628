import { Chip } from '@mui/material';
import { styled } from '@mui/material/styles';

const getColor = (color) => (color ? color : 'yellow');

const StyledChip = styled(Chip)(({ theme, color }) => ({
    '&.MuiChip-root': {
        background: theme.gradient.common[getColor(color)],
        color: theme.palette.common.white,
    },
}));

export default StyledChip;
