import { BsFillCollectionFill } from 'react-icons/bs';
import { PAGES } from './pages';
import { CondidatureImage, CondidatureStatus } from '../pages/Admin/Applications/Frensh';

export const ADMIN_DRAWER_TOP = [
    {
        icon: <BsFillCollectionFill />,
        title: 'Candidatures',
        url: PAGES.admin.url + '/' + PAGES.adminApplications.url,
    },
];

export const ADMIN_APPLICATIONS_COLUMN = [
    {
        field: 'id',
        hide: true,
    },
    {
        field: 'image',
        headerName: '',
        editable: false,
        sortable: false,
        width: 40,
        valueGetter: (value) => value.row,
        renderCell: (params) => <CondidatureImage {...params} />,
    },
    {
        field: 'fullname',
        headerName: 'Nom et prénom',
        editable: false,
        sortable: false,
        flex: 1,
    },
    {
        field: 'service',
        headerName: 'Service',
        editable: false,
        sortable: false,
        flex: 1,
    },
    {
        field: 'status',
        headerName: 'Service',
        editable: false,
        sortable: false,
        flex: 1,
        valueGetter: (value) => value.row,
        renderCell: (params) => <CondidatureStatus {...params} />,
    },
];
