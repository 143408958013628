import { Box, Container, Typography } from '@mui/material';
import React from 'react';
// import Image from '../../../assets/landing/header.jpg';
import { Navbar } from '../../../components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Slide1 from '../../../assets/landing/slider/slide-1.jpg';
import Slide2 from '../../../assets/landing/slider/slide-2.jpg';
import Slide3 from '../../../assets/landing/slider/slide-3.jpg';
import Slide4 from '../../../assets/landing/slider/slide-4.jpg';

const images = [Slide1, Slide2, Slide3, Slide4];

const Header = () => {
    return (
        <Box position="relative" width="100%">
            <Slider
                {...{
                    fade: true,
                    infinite: true,
                    speed: 5500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    lazyLoad: 'progressive',
                    autoplay: true,
                    autoplaySpeed: 5500,
                    arrows: false,
                }}
            >
                {images.map((image, index) => (
                    <>
                        <Box
                            key={index}
                            sx={{
                                height: { md: 550, xs: 850 },
                                backgroundImage: `url(${image})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                            className={index % 2 ? 'zoom-in' : 'zoom-out'}
                        />
                    </>
                ))}
            </Slider>
            <Box position="absolute" width="100%" top={0} right={0} zIndex={100}>
                <Navbar />
            </Box>
            <Box
                position="absolute"
                width="100%"
                height="100%"
                top="50%"
                right="50%"
                sx={(theme) => ({
                    transform: 'translate(50%, -50%)',
                    background: theme.gradient.secondary,
                    opacity: 0.7,
                })}
            />
            <Box position="absolute" width="100%" top="50%" right="50%" zIndex={10} sx={{ transform: 'translate(50%, -50%)' }}>
                <Container maxWidth="md" sx={{ flexGrow: 1 }}>
                    <Typography
                        sx={(theme) => ({
                            fontWeight: theme.fontWeight.light,
                            fontSize: theme.fontSize.lg,
                            color: theme.palette.common.white,
                            textAlign: 'center',
                            mb: 3,
                        })}
                    >
                        CoopLab - SIL
                    </Typography>
                    <Typography
                        sx={(theme) => ({
                            fontWeight: theme.fontWeight.bold,
                            fontSize: theme.fontSize['3xl'],
                            color: theme.palette.common.white,
                            textAlign: 'justify',
                            textAlignLast: 'center',
                        })}
                    >
                        {/* Étant le spin-off du Social Innovation Lab, le Cooplab est une plateforme dédiée à la co-construction de pratiques
                        innovantes dans le domaine de l'économie sociale et solidaire de notre pays et continent. */}
                        Un laboratoire d'expertise et de soutien au tissu coopératif marocain et africain et centre de compétences innovant
                        au service des acteurs de l'écosystème coopératif, des territoires et des communautés
                    </Typography>
                </Container>
            </Box>
        </Box>
    );
};

export default Header;
