import {
    BsBoxes,
    BsChatHeart,
    BsFileSlides,
    BsGlobeEuropeAfrica,
    BsHeart,
    BsPersonWorkspace,
    BsSearch,
    BsTrophy,
    BsTv
} from 'react-icons/bs';
import Academy from '../assets/landing/entities/academy.png';
import Growth from '../assets/landing/entities/growth.png';
import Links from '../assets/landing/entities/links.png';
import Thinking from '../assets/landing/entities/thinking.png';
import MourafakaWhite from '../assets/landing/programmes/mourafaka-white.png';
import Mourafaka from '../assets/landing/programmes/mourafaka.png';
import RawabitWhite from '../assets/landing/programmes/rawabit-white.png';
import Rawabit from '../assets/landing/programmes/rawabit.png';
import SookoaWhite from '../assets/landing/programmes/sookoa-white.png';
import Sookoa from '../assets/landing/programmes/sookoa.png';

export const ENTITES = [
    {
        image: Thinking,
        title: 'Cooplab Thinking',
        sub: 'Recherche & Développement',
        desc: 'CoopLab Thinking se consacre à la recherche et au développement de nouvelles idées et solutions pour le secteur coopératif.', // D'où ses rôles en",
        // bullets: [
        //     "Organisation d'événements de rencontre et échange d'expérience/expertise (Conférences scientifiques, forums et workshops thématiques).",
        //     'Observation, Veille et analyse scientifique et réglementaire.',
        //     'Prospection des tendances et usages.',
        //     'Audit, capitalisation et modélisation des processus.',
        // ],
    },

    {
        image: Growth,
        title: 'Cooplab Growth',
        sub: 'Accompagnement & Business Développement',
        desc: 'Le CoopLab Growth se dédie à favoriser la croissance et le développement des coopératives en leur offrant un accompagnement professionnel.', // Ses principales activités comprennent",
        // bullets: [
        //     'Incubation et accélération.',
        //     'Montage projets autour des coopératives.',
        //     'Design et développement de nouveaux produits.',
        //     'Valorisation & packaging.',
        //     'Mise en place de modèles de financements et partenariats.',
        //     "Développement des outils de gestion et de digitalisation de l'activité.",
        // ],
    },
    {
        image: Links,
        title: 'Cooplab Links',
        sub: 'Partage du Savoir-faire & Expertise',
        desc: "CoopLab Link's vise à créer un réseau de partage de connaissances et d'expertise au sein de la communauté coopérative.", // A cette fin l'équipe Links travaille sur",
        // bullets: [
        //     "La création et mise en plateforme collaborative digitale d'échange.",
        //     "Le développement d'un réseau d'experts et de compétences sur toute la chaîne de valeur (DATA).",
        //     "L'animation et la mise en réseau des acteurs et des compétences.",
        //     'Le pilotage des rencontres B2B.',
        //     "La captation de l'expertise internationale.",
        // ],
    },

    {
        image: Academy,
        title: 'Cooplab Academy',
        sub: 'Formation et renforcement des capacités',
        desc: "CoopLab Academy met l'accent sur la formation des individus et des coopératives afin de renforcer leurs compétences et leurs connaissances dans le domaine coopératif.", // et ce en travaillant sur",
        // bullets: [
        //     "Le développement des offres de formation et d'accompagnement / des parcours de formation spécifiques (sur place et à distance).",
        //     "L'accompagnement des filiales/entités du groupe dans la définition et la mise en œuvre des programmes au profit des coopératives (Plans de renforcement des compétences).",
        //     'La participation dans les appels à projet.',
        // ],
    },
];

export const PROGRAMMES = {
    mourafaka: {
        title: 'MOURAFAKA',
        logo: Mourafaka,
        logoWhite: MourafakaWhite,
        desc: "Mourafaka est un programme d'accompagnement et de formations des coopératives marocaines nouvellement crées. Le programme dans sa quatrième tranche accompagne la montée en compétence de 600 coopératives dans les 12 régions du Maroc.",
        bullets: [
            {
                title: 'Diagnostic Territorial Convivial',
                desc: 'Notre programme commence par une analyse approfondie des besoins spécifiques et potentialités de chaque coopérative là où elle est implantée, assurant ainsi une approche adaptée et efficace',
                icon: <BsSearch />,
            },
            {
                title: 'Formation Innovante des Membres',
                desc: 'Nous offrons des programmes de formation novateurs pour doter les coopératives de compétences essentielles, favorisant leur succès dans un environnement commercial en constante évolution',
                icon: <BsFileSlides />,
            },
            {
                title: 'Assistance Technique Personnalisée ',
                desc: 'Nos experts collaborent étroitement avec les coopératives, résolvant des défis spécifiques et optimisant leurs opérations, pour un plein impact économique et social.',
                icon: <BsChatHeart />,
            },
        ],
    },
    sookoa: {
        title: 'SOOKOA',
        logo: Sookoa,
        logoWhite: SookoaWhite,
        desc: '"Sookoa" est une plateforme de e-commerce dédiée au soutien des coopératives au Maroc et en Afrique en renforçant leur accès au marché. Elle offre un espace en ligne permettant d\'accroître la visibilité des produits proposés par ces coopératives.',
        bullets: [
            {
                title: 'Vitrine du E Commerce Solidaire',
                desc: "SOOKOA offre une vitrine en ligne dédiée à la découverte et à la promotion des produits locaux du Maroc et d'Afrique, mettant en valeur le savoir-faire des coopératives",
                icon: <BsTv />,
            },
            {
                title: 'Connectivité Globale',
                desc: "SOOKOA ambitionne de connecter les coopératives marocaines et africaines à un marché mondial grâce à la puissance du numérique, favorisant ainsi l'expansion de leurs activités.",
                icon: <BsGlobeEuropeAfrica />,
            },
            {
                title: 'Symbole de Changement ',
                desc: "SOOKOA représente bien plus qu'une plateforme, c'est un symbole de solidarité, de découverte et d'impact, servant de passerelle vers un avenir meilleur pour les coopératives et leurs communautés",
                icon: <BsTrophy />,
            },
        ],
    },
    rawabit: {
        title: 'RAWABIT',
        logo: Rawabit,
        logoWhite: RawabitWhite,
        desc: "Rawabit est un programme qui favorise la mise en place d'activités de réseautage, créant ainsi des opportunités de business entre les coopératives.",
        bullets: [
            {
                title: 'Renforcement de la collaboration',
                desc: "Développement d'un réseau d'experts et de compétences sur toute la chaîne de valeur, favorisant la collaboration entre les coopératives.",
                icon: <BsHeart />,
            },
            {
                title: "Création d'opportunités de business",
                desc: 'RAWABIT vise à créer des opportunités de projets communs entre les coopératives, favorisant ainsi une dynamique économique active.',
                icon: <BsBoxes />,
            },
            {
                title: 'Networking actif',
                desc: "Le programme encourage activement les activités de réseautage pour renforcer les liens, l'échange d'expertise et les partenariats au sein du secteur coopératif.",
                icon: <BsPersonWorkspace />,
            },
        ],
    },
};
