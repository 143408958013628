import { useMediaQuery } from '@mui/material';
import React from 'react';
import Desktop from './Desktop';
import Mobile from './Mobile';

const Frensh = ({ sticky }) => {
    // ========== VARIABLES
    const md = useMediaQuery((theme) => theme.breakpoints.up('md'));

    return <>{md ? <Desktop sticky={sticky} /> : <Mobile sticky={sticky} />}</>;
};

export default Frensh;
