import { Alert, Box, Snackbar, alpha } from '@mui/material';
import React, { useEffect, useReducer } from 'react';
import { Outlet } from 'react-router-dom';
import { Loader } from '../../components';
import { removeAlert, resetGeneralStates } from '../../context/actions';
import { initialData, reducer } from '../../context/reducer';
import { useScrollToTop } from '../../hooks';

const Base = () => {
    // ========== GET TO THE TOP TO THE PAGE
    useScrollToTop();

    // ========== UTILES
    const getLocalData = () => {
        const statesDataJSON = localStorage.getItem('states');
        return statesDataJSON ? JSON.parse(statesDataJSON) : initialData;
    };

    // ========== states
    const [states, dispatch] = useReducer(reducer, getLocalData());

    // ========== set localstorage
    useEffect(() => {
        localStorage.setItem('states', JSON.stringify(states));
    }, [states]);

    // ========== clear storage
    useEffect(() => {
        return () => {
            dispatch(resetGeneralStates());
        };
    }, []);

    return (
        <Box
            sx={(theme) => ({
                minHeight: '100vh',
                minWidth: '100vw',
                height: '100%',
                width: '100%',
            })}
        >
            {states.loader && (
                <Box
                    position="fixed"
                    sx={(theme) => ({ zIndex: theme.zIndex.modal + 2, backgroundColor: alpha(theme.palette.common.black, 0.25) })}
                    width="100vw"
                    height="100vh"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Loader />
                </Box>
            )}
            {states.alert && (
                <Snackbar open={true} autoHideDuration={2000} onClose={() => dispatch(removeAlert())}>
                    <Alert
                        onClose={() => dispatch(removeAlert())}
                        variant="filled"
                        severity={states.alert.severity}
                        sx={(theme) => ({
                            borderRadius: theme.borderRadius.md,
                            fontSize: theme.fontSize.sm,
                            width: '100%',
                            alignItems: 'center',
                            maxWidth: '30rem',
                            boxShadow: `${alpha(theme.palette[states.alert.severity]?.main, 0.25)} 0 0 0 2px`,
                        })}
                    >
                        {states.alert.message && states.alert.message.length !== 0 ? states.alert.message : 'Error has been occurred'}
                    </Alert>
                </Snackbar>
            )}
            <Outlet context={{ states, dispatch }} />
        </Box>
    );
};

export default Base;
