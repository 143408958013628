import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(2),
    boxShadow: theme.shadows.default,
    width: '100%',
    borderRadius: theme.borderRadius.md,
}));

export default StyledCard;
