import { Menu, alpha, styled } from '@mui/material';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: theme.borderRadius.md,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.grey[300],
        border: 0,
        boxShadow: theme.shadows.default,
        '& .MuiMenu-list': {
            padding: theme.spacing(1.5, 1),
        },
        '& .MuiMenuItem-root': {
            transition: 'all 0.3s ease',
            borderRadius: theme.borderRadius.md,
            padding: theme.spacing(1.5, 1),
            '& .MuiSvgIcon-root': {
                fontSize: theme.fontSize.md,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active, &:hover': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));

export default StyledMenu;
