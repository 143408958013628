import {
    BsFillCheckCircleFill,
    BsFillClockFill,
    BsFillHouseDoorFill,
    BsFillMotherboardFill,
    BsFillPersonFill,
    BsFillXCircleFill,
} from 'react-icons/bs';
import { PAGES } from './pages';

export const USER_DRAWER_TOP = [
    {
        icon: <BsFillHouseDoorFill />,
        title: "Vue d'ensemble",
        url: PAGES.user.url + '/' + PAGES.userOverview.url,
    },
    {
        icon: <BsFillMotherboardFill />,
        title: 'Services',
        url: PAGES.user.url + '/' + PAGES.userServices.url,
    },
];

export const USER_DRAWER_BOTTOM = [
    {
        icon: <BsFillPersonFill />,
        title: 'Profile',
        url: PAGES.user.url + '/' + PAGES.userProfile.url,
    },
    {
        icon: <BsFillMotherboardFill />,
        title: 'Services',
        url: PAGES.user.url + '/' + PAGES.userProfile.url,
    },
];

export const DASHBOARD_SERVICES_STATUS_CARDS = [
    {
        color: 'success',
        title: 'Accepté',
        icon: <BsFillCheckCircleFill />,
        number: '28',
    },
    {
        color: 'yellow',
        title: 'En attente',
        icon: <BsFillClockFill />,
        number: '03',
    },
    {
        color: 'error',
        title: 'Refusé',
        icon: <BsFillXCircleFill />,
        number: '01',
    },
];
