import React from 'react';
import Header from './Header';
import { Stack } from '@mui/material';
import Entites from './Entites';
import { Footer } from '../../../components';
import AboutUs from './AboutUs';
import Programmes from './Programmes';
import Partenaires from './Partenaires';

const Frensh = () => {
    return (
        <Stack spacing={15}>
            <Header />
            <AboutUs />
            <Entites />
            <Programmes />
            <Partenaires />
            <Footer />
        </Stack>
    );
};

export default Frensh;
